void main() {
    //yUv = uv;

    vec4 mvPosition = modelViewMatrix * vec4( position, 1. );

    //gl_PointSize = size*10.;
    gl_PointSize = 50000. * (1./-mvPosition.z);
    gl_Position = projectionMatrix * mvPosition;
    //gl_Position = projectionMatrix * (modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0) + vec4(position.x, position.y, 0.0, 0.0));

}