import * as THREE from 'three';
import fragment from './shaders/fragment.glsl'
import vertex from './shaders/vertex.glsl'
import {VRButton} from './vrbutton.js';

export default class Sketch {
    constructor() {
        const  width = window.innerWidth, height = window.innerHeight;
        
        this.camera = new THREE.PerspectiveCamera( 45, width / height, 0.1, 3000 );
        this.camera.position.z = 1000;

        this.scene = new THREE.Scene();
        
        this.addMesh();

        this.renderer = new THREE.WebGLRenderer( { antialias: true } );
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize( width, height );
        this.renderer.xr.enabled = true;
        this.renderer.setAnimationLoop( this.render_loop.bind(this) );

        document.getElementById("container").appendChild( this.renderer.domElement );
        document.body.appendChild(VRButton.createButton( this.renderer ));

        this.time = 0;
       // this.render();
    }

    addMesh() {
        this.geometry = new THREE.PlaneGeometry( 1000, 1000, 10, 10 ); // new THREE.BoxGeometry( 0.2, 0.2, 0.2 ); // THREE.PlaneBufferGeometry(1,1); //
        //this.material = new THREE.MeshNormalMaterial({side: THREE.DoubleSide});
        this.material = new THREE.ShaderMaterial( {
            fragmentShader: fragment,
            vertexShader: vertex,
            uniforms: {
                progress: {type: "f", value: 0}
            },
            side: THREE.DoubleSide
        });

        this.mesh = new THREE.Points( this.geometry, this.material );
        this.scene.add( this.mesh );
    }

    render_raf() {
        this.time++;
        // console.log(this.time);
        this.mesh.rotation.x += 0.01;
        this.mesh.rotation.y += 0.02;
        
	    this.renderer.render( this.scene, this.camera );
        window.requestAnimationFrame( this.render.bind(this) );
    }

    render_loop() {
        this.time++;
        // console.log(this.time);
        this.mesh.rotation.x += 0.01;
        this.mesh.rotation.y += 0.02;
        
	    this.renderer.render( this.scene, this.camera );
    }

    onWindowResize() {      
        const  width = window.innerWidth, height = window.innerHeight;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
    }
}

var sk = new Sketch();
window.addEventListener('resize', sk.onWindowResize.bind(sk), false);
